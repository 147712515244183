@import 'theme';

$offset: 187;

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}
	50% {
		stroke-dashoffset: calc($offset / 4);
		transform: rotate(180deg);
	}
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(360deg);
	}
}

.spinner {
	display: flex !important; // Hack to ensure the spinner is not shown in a broken state before the css is loaded
	transform: rotate(0deg);
	animation: rotator 1.4s linear infinite;
	width: 100%;
	height: 100%;
	animation-fill-mode: forwards;
}

.path {
	stroke-dasharray: 188;
	stroke-dashoffset: 187;
	transform-origin: center;
	animation: dash 1.4s ease-in-out infinite;
	fill: none;
	stroke: var(--blue-500);
	stroke-width: 3.5;
	stroke-linecap: round;
}
