@import 'theme';

.editableWrapper {
	display: flex;
	flex-direction: row;
	padding: 0;
	border-radius: 100%;
	margin: 0;
	border: 0;
	cursor: none;
	background-color: transparent;
	justify-content: center;
	align-items: center;
	position: relative;
	outline: 0;
	transition: background-color 0.5s ease-in-out;
	&.edit:focus-visible {
		box-shadow: 0 0 0 0.1875rem var(--color-focus);
	}
	&::before {
		display: block;
		width: 100%;
		height: 100%;
		transition: background-color cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
		z-index: 5;
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		border-radius: 50%;
	}
	&.edit:hover,
	&.edit:focus-visible {
		cursor: pointer;
		&::before {
			/* stylelint-disable-next-line @web-apps/no-custom-colors -- We do not have such a transparent black color yet */
			background-color: rgba(0, 0, 0, 0.5);
		}
		&::after {
			background-image: url('../../src/media/icons/icon-camera-white-line-default-24.svg');
			position: absolute;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			background-position: center;
			width: 100%;
			content: '';
			height: 100%;
			top: 0;
			z-index: 6;
			left: 0;
			display: flex;
		}
	}
	&.edit.sixtyFour {
		&::after {
			background-size: 64px;
		}
	}
}
