@import 'theme';

.container {
	min-height: 0;
	padding-bottom: 42px;
}

.contentContainer {
	text-align: center;
	line-height: 1.5;
}

.mailLinkContainer {
	display: flex;
	justify-content: center;
}

.mailLink {
	font-size: 1.5rem;
	color: var(--text-500);
	margin-top: 24px;
	border-bottom-color: var(--blue-500);

	@media (min-width: $breakpoint-width-md) {
		border-bottom-color: unset;
	}
}
