@import 'theme';

.cageDialogButton {
	color: var(--white);
	padding: 24px 24px 0px 24px;
	width: initial;
	border: 0;
}

.cageDialog {
	position: fixed;
	top: 0;
	right: 0;

	@include isSmallerThanSmallDevice {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}
