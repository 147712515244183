@import 'theme';

.overlay {
	display: block;
	background-color: var(--overlay-dark);
	pointer-events: auto;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1449;

	@media (min-width: $breakpoint-width-xl) {
		display: none;
	}

	&.closed {
		display: none;
	}
}

.navigation {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	color: var(--satellite-overlay-grey);

	min-width: 20rem;
	width: 20rem;
	background-color: var(--satellite-background-gray);

	padding: 1rem 0 1rem 1rem;
	z-index: 1449;

	transition-property: min-width, width, padding-right;
	transition-timing-function: ease-in-out;
	transition-duration: 0.5s;

	& > div:last-child {
		padding-right: 1rem;
	}

	@media (max-width: calc($breakpoint-width-xl - 1px)) {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1450;
	}

	nav {
		display: flex;
		flex-direction: column;

		flex: 1;
		overflow: hidden;

		& > * {
			padding-right: 1rem;
		}

		& > ul {
			:first-child > a > span {
				font-weight: 700;
			}

			overflow-y: auto;
			overflow-x: hidden;

			scrollbar-width: auto;
			scrollbar-color: var(--gray-700) var(--transparent);
			scrollbar-gutter: stable;
			margin-top: 0;
			margin-right: 0.25rem;

			&::-webkit-scrollbar-thumb {
				background-color: var(--gray-700);
				border-radius: 0.25rem;
			}

			&::-webkit-scrollbar {
				width: 0.5rem;
			}

			position: relative;

			&.scrolled {
				&:before {
					visibility: visible;
				}
			}

			@media (pointer: coarse) {
				margin-top: 1rem;
			}

			&:before,
			&:after {
				position: sticky;
				content: '';
				background: linear-gradient(
					360deg,
					var(--transparent) 0%,
					var(--satellite-background-gray) 100%
				);
				width: 100%;
				display: block;
				min-height: 1rem;
			}

			&:before {
				top: 0rem;
				visibility: hidden;
			}

			&:after {
				bottom: 0rem;
				transform: rotate(180deg);
			}
		}

		.communicationButtons {
			margin-top: 1rem;

			div {
				margin: 0;
				padding: 0;
				gap: 0.25rem;

				button {
					background-color: var(--satellite-orange-item);
					font-size: 0.875rem;
					font-weight: 700;
					line-height: 1.5rem;
					color: var(--white);
					margin: 0;
					padding: 0.5rem;

					&:hover {
						background-color: var(--satellite-orange-item);
					}

					&:first-child {
						border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
					}

					&:not(:first-child):not(:last-child) {
						border-radius: 0.25rem;
					}

					&:last-child {
						border-radius: 0.25rem 0.5rem 0.5rem 0.25rem;
					}
				}
			}
		}

		& ul {
			padding: 0;
			flex: 1;
			display: flex;
			flex-direction: column;

			li {
				margin: 0.25rem 0;
				list-style: none;
			}
		}
	}

	&.inTransition {
		nav {
			.header {
				button,
				.toggleButton {
					display: none;
				}
			}
		}

		& > div:last-child {
			padding-right: 0;
		}
	}

	.header {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
		justify-content: space-between;
		align-items: center;

		padding: 0 1rem 0 0;
		max-width: 100%;
		min-height: 3.25rem;

		overflow: hidden;

		.logo {
			flex: 1;
			height: 2rem;
			padding-left: 0.75rem;
			object-fit: contain;
			object-position: left;
		}

		button,
		.toggleButton {
			display: block;
			width: 2rem;
			height: 2rem;
			padding: 0.25rem;
			justify-content: center;
			align-items: center;

			border-radius: 0.5rem;
			background: var(--satellite-overlay-grey);
			border: none;
			cursor: pointer;
			outline: inherit;
			text-align: left;

			&:hover {
				background-color: var(--satellite-overlay-grey);
			}

			&:active {
				background-color: var(--gray-700);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
			}

			@media (hover: none) {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 3rem;
				height: 3rem;
			}
		}
	}

	&.invertColors {
		box-shadow: 1px 0 0 0 var(--satellite-background-gray);
		color: var(--gray-800);
		background-color: var(--white);

		.adminAreaToggle {
			background-color: var(--gray-50);

			label {
				color: var(--gray-800);

				&:not(.active) {
					&::after {
						color: var(--gray-800);
					}
				}
			}
		}

		nav > ul {
			&:before,
			&:after {
				background: linear-gradient(360deg, var(--transparent) 0%, var(--white) 100%);
			}
		}

		.toggleButton {
			background-color: var(--gray-50);
			color: var(--black);

			img {
				fill: none;
			}
		}
	}
}

.closed {
	padding: 1rem;
	min-width: 5rem;
	width: 5rem;
	overflow: visible;

	& > div:last-child {
		padding-right: 0;
	}

	.header {
		flex-direction: row;
		max-width: 2.5rem;

		.logo {
			height: 2rem;
		}

		button,
		.toggleButton {
			display: none;
			position: fixed;
			left: 4rem;
			rotate: 180deg;
			height: 2rem;
			width: 2rem;
		}
	}

	nav {
		ul {
			margin: 0;
			padding: 0;
			scrollbar-gutter: unset;
			position: relative;
			:last-child > a > a > span {
				display: none;
			}
		}
	}

	& > * {
		padding: 0;
	}

	& > ul {
		scrollbar-gutter: unset;
		margin: 0;
	}

	&:hover {
		.header {
			& > button,
			& > .toggleButton {
				display: block;
			}
		}
	}

	@media (max-width: calc($breakpoint-width-xl - 1px)) {
		transition: all 0s;
		display: none;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.navigation {
		transition: all 0s;

		&.inTransition {
			nav {
				.header {
					button,
					.toggleButton {
						display: block;
					}
				}
			}
		}
	}
}

div.mobileSectionSwitcher {
	@media (min-width: calc($breakpoint-width-md)) {
		display: none;
	}
}
