@import 'theme';

.button {
	margin: 0;
	border: 1px solid var(--gray-300);
	border-radius: 2px;
	padding: 7px 10px;
	background-color: var(--white);
	font-weight: 700;
	transition-property: background-color, border-color, color, fill, stroke;
	transition-duration: 200ms;
	transition-timing-function: linear;
	user-select: none;

	@include focussable;
}
.button:hover {
	background-color: var(--blue-25);
	border: 1px solid var(--blue-500);
}

.button:active {
	background-color: var(--blue-25);
	border: 1px solid var(--blue-500);
}

.button:disabled {
	color: var(--gray-100);
	background-color: var(--white);
}

.primary {
	color: var(--white);
	background-color: var(--blue-500);

	// Remove border and set its color for use in ButtonGroup
	padding: 8px 11px;
	border-width: 0;
	border-color: var(--blue-700);
	font-weight: 700;

	@include focussable;
}

.primary:hover {
	border-width: 0;
	background-color: var(--blue-700);
}

.primary:active {
	background-color: var(--blue-800);
}

.primary:disabled {
	color: var(--white);
	background-color: var(--blue-300);
}

.button.danger {
	color: var(--red-500);
	border-color: var(--red-500);
}

.button.danger:hover {
	color: var(--white);
	background-color: var(--red-500);
}

.button.danger:active {
	background-color: var(--red-500);
}
