.enter {
	opacity: 0;
}

.exit {
	opacity: 1;
}

.enterActive {
	opacity: 1;
}

.exitActive {
	opacity: 0;
}

.enterActive,
.exitActive {
	transition: opacity 150ms;
}
