@import 'theme';

.startCodeButtonContainer {
	padding: 1rem 1rem 0 0;
	display: flex;

	.startCodeButton {
		box-shadow: var(--blue-500) 0 0 0 1px inset;
		border-radius: 4px;
		width: 100%;
		height: 58px;

		box-sizing: border-box;
		padding: 0.5rem;
		transition: box-shadow 200ms ease;
		background-color: var(--blue-25);

		@include focussable;

		&:hover {
			background-color: var(--blue-50);

			p {
				color: var(--blue-600);
			}
		}
	}

	p {
		margin: 0;
		color: var(--blue-500);
		text-align: center;

		&.enterCodeText {
			font-size: 1rem;
			font-weight: 700;
		}

		&.completeVerificationText {
			font-size: 0.75rem;
		}
	}
}
