@import 'theme';

.dropOut {
	display: none;
	position: absolute;
	min-width: 13.5rem;
	max-width: 18rem;
	padding: 0;
	margin: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	top: 4rem;

	border-radius: 0.5rem;
	overflow: hidden;
	background: var(--white);

	/* stylelint-disable-next-line @web-apps/no-custom-colors -- We do not have pandaUI box-shadow tokens yet. */
	box-shadow: 0 0 0.0625rem 0 rgba(0, 0, 0, 0.04), 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.04),
		0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.04);
	z-index: 1000;

	&.open {
		display: block;
	}

	ul {
		top: 4rem;
		margin: 0;
		padding: 0;

		a {
			padding: 0.75rem 1rem;
			text-decoration: none;
			width: 100%;
			display: block;
			outline: 0;

			&:active {
				background: var(--blue-50);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
			}

			&:hover {
				background: var(--blue-25);
				color: var(--gray-800);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
			}
		}

		li {
			list-style: none;
			outline: 0;
			width: 100%;

			hr {
				border-top: 0.0625rem solid var(--gray-100);
				margin: 0;
			}
		}
	}
}

.divider {
	padding: 0.25rem 0.5rem;
}
