@import 'theme';

@media (min-width: 768px) {
	.center {
		width: 26.25rem;

		.content {
			margin: 2.5rem 3.75rem;
		}
	}
}

@media (max-width: 768px) {
	.center {
		width: 100%;
		height: 100%;

		.content {
			margin: 2.5rem auto;
			width: 20rem;
		}
	}
}

.wrapper {
	margin: 0 auto;
	height: 100%;

	background: url('../../../media/backgrounds/login_background_large.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.email {
	margin: 0.5rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.center {
	display: flex;
	flex-direction: column;

	background: var(--white);
	border-radius: 8px;
	.header {
		display: flex;
		align-items: center;
		justify-content: center;

		background: var(--black);
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		.logo {
			margin: 3.75rem;
		}
	}

	.content {
		float: left;
		.wrapper {
			& > :first-child {
				margin-bottom: 1rem;
			}
			margin-bottom: 2rem;
		}

		.setPassword {
			.title {
				font-size: 1.125rem;
				line-height: 1.125rem;
				font-weight: 700;
				text-align: center;
			}

			p {
				line-height: 1.5rem;
			}

			& > :last-child {
				margin-top: 1rem;
			}
		}
	}

	.hr {
		border-top: 1px solid var(--gray-100);
		overflow: visible;
		text-align: center;
		margin: 0;
		&:after {
			display: inline-block;
			position: relative;
			top: -0.69rem;
			padding: 0 0.25rem;

			background-color: var(--white);
			font-size: 1rem;
			color: var(--gray-800);
			line-height: 0.75rem;
			content: attr(data-content);
		}
	}

	.idp {
		display: block;
		text-decoration: none;
		width: 100%;
		height: 2.5rem;
		padding: 0;
		margin: 0.5rem 0;
		float: left;

		&:focus,
		&:focus-visible,
		&:focus-within {
			background: var(--color-focus);
			padding: 3px;
			border-color: var(--white);
		}

		&:hover > span {
			background: var(--gray-25);
		}

		span {
			box-sizing: border-box;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			height: 100%;

			background: var(--white);

			border: 0.0625rem solid var(--gray-100);
			border-radius: 0.125rem;

			color: var(--gray-600);

			font-size: 0.875rem;
			line-height: 0.875rem;
			font-weight: 600;
			font-family: PX-Grotesk, BlinkMacSystemFont, -apple-system, Trebuchet MS, Helvetica,
				'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', Roboto, Arial, sans-serif;

			&.google:before {
				content: url('./img/logo_google.svg');
				display: inline-block;
				width: 1.125rem;
				height: 1.125rem;
			}
			&.microsoft:before {
				content: url('./img/logo_microsoft.svg');
				display: inline-block;
				width: 1.125rem;
				height: 1.125rem;
			}
			&.apple:before {
				content: url('./img/logo_apple.svg');
				display: inline-block;
				width: 1.125rem;
				height: 1.125rem;
			}
		}

		&.clicked {
			span {
				background: var(--gray-75);
				border-color: var(--gray-100);
			}
		}
	}
	.first {
		margin-top: 2rem;
	}
	.hidden {
		display: none;
	}
}
