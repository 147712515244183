@import 'theme';

.overlay {
	z-index: $layer-dialog;
	position: fixed;
	background: var(--overlay-light);
	@media (min-width: $breakpoint-width-md) {
		background: var(--overlay-dark);
	}
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	overflow: auto;
	user-select: none;

	> * {
		user-select: text;
	}
}
