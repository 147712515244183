@import 'theme';

.pill {
	padding: 0 0.5rem;
	font-family: $font-px-grotesk;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25rem;
	letter-spacing: 0.015rem;

	border-radius: 0.5rem;
	text-transform: uppercase;

	&.new {
		background: var(--green-200);
		color: var(--green-900);
	}

	&.beta {
		background: var(--purple-100);
		color: var(--purple-900);
	}
}
