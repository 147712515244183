@import 'theme';

.help {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		width: 2rem;
		height: 2rem;
		padding: 0.25rem;

		border-radius: 0.25rem;
		border: none;
		cursor: pointer;
		outline: inherit;
		text-align: left;

		&:hover {
			background-color: var(--blue-50);
		}

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
		}

		@media (max-width: calc($breakpoint-width-xl - 1px)) {
			background-color: var(--black);

			&:hover {
				background-color: var(--gray-900);
			}
			&:active {
				background-color: var(--gray-800);
			}
			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
			}

			img {
				padding: 0.75rem;
				filter: invert(99%) sepia(0%) saturate(1534%) hue-rotate(184deg) brightness(115%)
					contrast(78%);
			}
		}
	}
}
