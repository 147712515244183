$breakpoint-width-sx: 20rem;
$breakpoint-width-sm: 640px;
$breakpoint-width-md: 768px;
$breakpoint-width-lg: 1024px;
$breakpoint-width-xl: 1280px;
$breakpoint-width-2xl: 1536px;
$breakpoint-width-3xl: 1920px;

$header-height-mobile: 64px;
$header-height-desktop: 75px;

@mixin showFullView {
	@media (min-width: $breakpoint-width-lg) {
		@content;
	}
}

@mixin isMediumOrExtraLargeDevice {
	@media (min-width: $breakpoint-width-xl),
	(min-width: $breakpoint-width-md) and (max-width: calc(#{$breakpoint-width-lg} - 1px)) {
		@content;
	}
}

@mixin isSmallerThanSmallDevice {
	@media (max-width: calc(#{$breakpoint-width-md} - 1px)) {
		@content;
	}
}

@mixin isMinMediumDevice {
	@media (min-width: calc(#{$breakpoint-width-md})) {
		@content;
	}
}

@mixin isMinLargeDevice {
	@media (min-width: calc(#{$breakpoint-width-lg})) {
		@content;
	}
}

@mixin isSmallerThanMediumDevice {
	@media (max-width: calc(#{$breakpoint-width-lg} - 1px)) {
		@content;
	}
}

@mixin isSmallerThanLargeDevice {
	@media (max-width: calc(#{$breakpoint-width-xl} - 1px)) {
		@content;
	}
}

@mixin isSmallerThanExtraLargeDevice {
	@media (max-width: calc(#{$breakpoint-width-2xl} - 1px)) {
		@content;
	}
}

@mixin isExtraLargeDevice {
	@media (min-width: $breakpoint-width-2xl) {
		@content;
	}
}
