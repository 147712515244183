.hideArrows[type='number'] {
	-moz-appearance: textfield;
}

.hideArrows::-webkit-outer-spin-button,
.hideArrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input::-ms-reveal {
	display: none;
}
