@import 'theme';

a.item {
	display: flex;
	gap: 0.5rem;

	height: 3rem;

	flex-direction: row;
	align-items: center;

	font-family: $font-px-grotesk;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;

	border-radius: 0.5rem;
	color: var(--black);
	text-decoration: none;
	padding: 0.5rem 0.75rem;
	background-color: var(--satellite-background-gray);

	.icon {
		display: block;
		aspect-ratio: 1;
		height: 1.5rem;
		width: 1.5rem;
		background-color: var(--black);
		mask-repeat: no-repeat;
		mask-position: center;
	}

	@media (min-width: $breakpoint-width-xl) {
		&.open {
			justify-content: center;
		}
	}

	@media (min-width: $breakpoint-width-xl) {
		&.slim {
			row-gap: 0;

			.text {
				display: none;
			}
		}
	}

	&:hover {
		background: var(--satellite-overlay-grey);
		color: var(--black);
	}

	&:focus-visible {
		box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
	}

	&.icon {
		font-weight: bold;
		padding: 0.5rem 0.5rem 0.5rem 0.75rem;
	}

	&.invertColors {
		color: var(--gray-700);
		background-color: var(--white);
		box-sizing: border-box;
		font-weight: 700;

		&:hover {
			background-color: var(--blue-50);
		}
		&.inGroup {
			color: var(--gray-800);
			font-weight: 400;
		}

		.icon {
			background-color: var(--gray-700);
		}

		&.active {
			background-color: var(--blue-50);
			color: var(--blue-500);
			font-weight: bold;

			.icon {
				background: var(--blue-500);
			}
		}
	}

	&.active:not(.invertColors) {
		background: var(--satellite-overlay-grey);
		color: var(--satellite-orange-item);
		font-weight: bold;

		.icon {
			background: var(--satellite-orange-item);
		}
	}
}
