@import 'theme';

li.groupElement {
	&.closed > button > span.text {
		display: none;
	}

	&.transitioning:not(.open) {
		button::after {
			display: none;
		}
	}

	button {
		display: flex;
		gap: 0.5rem;
		height: 3rem;
		flex-direction: row;
		align-items: center;
		font-family: $font-px-grotesk;
		font-size: 1rem;
		font-style: normal;
		font-weight: bold;
		border-radius: 0.5rem;
		color: var(--black);
		text-decoration: none;
		padding: 0.5rem 0.75rem;
		background-color: var(--satellite-background-gray);
		border: 0;
		width: 100%;
		position: relative;
		@include focussable;

		.icon {
			display: block;
			aspect-ratio: 1;
			height: 1.5rem;
			width: 1.5rem;
			background-color: var(--black);
			mask-repeat: no-repeat;
			mask-position: center;
		}

		&::after {
			content: '';
			mask-image: url(../img/icon-triangle_down-black-line.svg);
			background-size: 1rem;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			mask-repeat: no-repeat;
			mask-position: center;
			background-color: var(--black);
			width: 1rem;
			height: 1rem;
			right: 1rem;
			position: absolute;
			transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
			padding: 1rem;
		}

		&.open {
			color: var(--black);
			&::after {
				transform: rotate(180deg);
			}
		}
	}

	ul.group {
		margin-top: 0.5rem;
		margin-left: 2.5rem;

		li.groupTitle {
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: 0.875rem;
			padding: 1.125rem 1.25rem 1rem 0.5rem;
			text-overflow: ellipsis;
			text-transform: uppercase;
		}
	}

	ul:not(.open) {
		display: none;
	}

	ul.open {
		display: flex;
	}

	&.closed {
		button {
			&::after {
				content: unset;
			}
		}
	}

	&.closed:not(.transitioning) {
		position: relative;

		ul {
			position: fixed;
			left: 5.25rem;
			border-radius: 0.5rem;
			/* stylelint-disable-next-line @web-apps/no-custom-colors -- We do not have pandaUI box-shadow tokens yet. */
			box-shadow: 0 0 0.0625rem 0 rgba(0, 0, 0, 0.04), 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.04),
				0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.04);
			padding: 0;
			transform: translateY(-48px);
			color: var(--black);
			margin-left: 0;
			z-index: 900;
			li {
				a {
					span {
						display: block !important;
					}
				}

				&:last-child {
					a {
						border-bottom-left-radius: 0.5rem;
						border-bottom-right-radius: 0.5rem;
					}
				}
			}
		}
	}

	&:not(.closed) {
		ul.group {
			li.groupTitle {
				display: none;
			}
		}
	}

	&.transitioning {
		ul {
			display: none;
		}
	}

	ul {
		background-color: var(--satellite-background-gray);
		border-radius: 0.5rem;
	}

	&.closed {
		ul {
			li {
				a {
					border-radius: 0;
				}
			}
		}
	}

	&.invertColors {
		--hover-backround-color: var(--blue-50);
		button {
			color: var(--gray-700);
			background-color: var(--white);
			box-sizing: border-box;
			font-weight: bold;
			.icon {
				background-color: var(--gray-700);
			}
			&::after {
				background-color: var(--gray-700);
			}
			&.open {
				.icon {
					background-color: var(--transparent);
				}
				&::after {
					background-color: var(--transparent);
				}
				color: var(--satellite-background-gray);
			}
			&.hasOpenSubPage {
				&.open {
					.icon {
						background-color: var(--black);
					}
					&::after {
						background-color: var(--black);
					}
				}
				&:not(.open) {
					color: var(--blue-500);
					background-color: var(--blue-50);
					.icon {
						background-color: var(--blue-500);
					}
					&::after {
						background-color: var(--blue-500);
					}
				}
				color: var(--black);
			}
		}

		ul {
			background-color: var(--white);
		}
	}
	&:not(.invertColors) {
		button {
			&.hasOpenSubPage {
				&.open {
					span {
						color: var(--satellite-orange-item);
					}

					.icon {
						background-color: var(--satellite-orange-item);
					}

					&::after {
						background-color: var(--satellite-orange-item);
					}
				}

				&:not(.open) {
					color: var(--satellite-orange-item);
					background-color: var(--satellite-overlay-grey);

					.icon {
						background-color: var(--satellite-orange-item);
					}

					&::after {
						background-color: var(--satellite-orange-item);
					}
				}

				color: var(--black);
			}
			&.open {
				.icon {
					background-color: var(--black);
				}
			}
			&.closed {
				span {
					color: var(--satellite-overlay-grey);
				}
			}
		}
	}

	&:not(.invertColors) {
		--hover-backround-color: var(--blue-900);
	}

	button {
		&:hover {
			background-color: var(--satellite-overlay-grey);
		}
	}
}
