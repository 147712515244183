@import 'theme';

.faxButton {
	border-top-right-radius: 0.125rem;
	border-bottom-right-radius: 0.125rem;

	@media (min-width: $breakpoint-width-lg) {
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
	}
}

.pureCommunicationButtonsContainer {
	display: flex;
	flex-direction: row;
	margin: 1rem 1rem 0 1rem;

	&:first-child {
		margin-top: 1.5rem;
	}

	button {
		@include focussable;

		flex-grow: 1;
		flex-basis: 33%;
		background: var(--blue-500);
		border: none;
		font-size: 0.75rem;
		font-weight: 700;
		color: var(--white);
		padding: 0.625rem;
		line-height: 1;
		margin: 0 0.0625rem;
		transition: background-color 200ms ease;

		&:hover {
			background-color: var(--blue-700);
		}

		&:active {
			background-color: var(--blue-800);
		}

		@media (pointer: coarse) {
			padding: 1rem 0.625rem;
		}

		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;
			margin-left: 0;
		}

		&:nth-child(2) {
			border-radius: 0;

			&.mobile {
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
			margin-right: 0;

			&.mobile {
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
		}

		&:only-child {
			border-radius: 0.25rem;
		}
	}
}
