@import 'theme';

.header {
	position: sticky;
	top: 0;
	background: var(--black);

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	z-index: 1000;

	transition: box-shadow 0.2s ease-in-out;

	&.scrolled {
		/* stylelint-disable-next-line @web-apps/no-custom-colors -- We do not have pandaUI box-shadow tokens yet. */
		box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.08);
	}

	@media (min-width: $breakpoint-width-xl) {
		background: var(--gray-25);
		padding: 1rem 5rem 1rem 5rem;
		flex-wrap: nowrap;
	}

	&:last-child {
		margin-left: auto;
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 1.5rem;
		align-items: center;

		padding-top: 0.625rem;
		padding-right: 1rem;
		padding-bottom: 0.625rem;

		& > svg {
			margin: 0;
		}

		@media (min-width: $breakpoint-width-xl) {
			padding: 0;
		}

		@media (max-width: calc($breakpoint-width-md - 1px)) {
			& > :nth-child(2),
			& > :nth-child(3) {
				display: none;
			}
		}
	}

	.left {
		display: flex;
		padding-top: 0.625rem;
		padding-left: 1rem;
		padding-bottom: 0.625rem;
		gap: 1rem;

		@media (min-width: $breakpoint-width-xl) {
			display: none;
		}
	}

	& > :nth-child(2) {
		background: var(--gray-25);
		order: 4;
		width: 100%;

		padding: 0.5rem 1rem;

		@media (min-width: $breakpoint-width-xl) {
			background: none;
			order: 0;
			width: auto;
			padding: 0;
		}
	}

	.logo {
		width: 8rem;
		height: 2.25rem;
		margin: 0.5rem;
		display: flex;
		object-position: left;
		object-fit: contain;
	}

	&:not(.withCustomLogo) {
		.logo {
			@media (max-width: calc($breakpoint-width-md - 1px)) {
				width: 1.56rem;
				object-fit: cover;
			}
		}
	}

	&.withCustomLogo {
		.logo {
			@media (max-width: calc($breakpoint-width-lg - 1px)) {
				width: 1.56rem;
				object-fit: cover;
			}
		}
	}

	.helpdesk {
		filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
			brightness(104%) contrast(104%);

		@media (min-width: $breakpoint-width-xl) {
			filter: brightness(0) saturate(100%) invert(16%) sepia(0%) saturate(1%) hue-rotate(244deg)
				brightness(101%) contrast(88%);
		}
	}
}
