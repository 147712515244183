:global(.group):hover .backgroundHover {
	background: radial-gradient(
		50% 50% at 50% 50%,
		theme('colors.blue.200') 37.45%,
		theme('colors.white') 37.45%
	);
}

:global(.group):active .backgroundActive {
	background: radial-gradient(
		50% 50% at 50% 50%,
		theme('colors.blue.300') 37.45%,
		theme('colors.white') 37.45%
	);
}
