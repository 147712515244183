@import './assets/icons/panda-icons/icons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--black: theme('colors.black');
		--white: theme('colors.white');
		--transparent: theme('colors.transparent');

		--gray-25: theme('colors.gray.25');
		--gray-50: theme('colors.gray.50');
		--gray-75: theme('colors.gray.75');
		--gray-100: theme('colors.gray.100');
		--gray-200: theme('colors.gray.200');
		--gray-300: theme('colors.gray.300');
		--gray-400: theme('colors.gray.400');
		--gray-500: theme('colors.gray.500');
		--gray-600: theme('colors.gray.600');
		--gray-700: theme('colors.gray.700');
		--gray-800: theme('colors.gray.800');
		--gray-900: theme('colors.gray.900');

		--blue-25: theme('colors.blue.25');
		--blue-50: theme('colors.blue.50');
		--blue-75: theme('colors.blue.75');
		--blue-100: theme('colors.blue.100');
		--blue-200: theme('colors.blue.200');
		--blue-300: theme('colors.blue.300');
		--blue-400: theme('colors.blue.400');
		--blue-500: theme('colors.blue.500');
		--blue-600: theme('colors.blue.600');
		--blue-700: theme('colors.blue.700');
		--blue-800: theme('colors.blue.800');
		--blue-900: theme('colors.blue.900');

		--red-25: theme('colors.red.25');
		--red-50: theme('colors.red.50');
		--red-75: theme('colors.red.75');
		--red-100: theme('colors.red.100');
		--red-200: theme('colors.red.200');
		--red-300: theme('colors.red.300');
		--red-400: theme('colors.red.400');
		--red-500: theme('colors.red.500');
		--red-600: theme('colors.red.600');
		--red-700: theme('colors.red.700');
		--red-800: theme('colors.red.800');
		--red-900: theme('colors.red.900');

		--orange-25: theme('colors.orange.25');
		--orange-50: theme('colors.orange.50');
		--orange-75: theme('colors.orange.75');
		--orange-100: theme('colors.orange.100');
		--orange-200: theme('colors.orange.200');
		--orange-300: theme('colors.orange.300');
		--orange-400: theme('colors.orange.400');
		--orange-500: theme('colors.orange.500');
		--orange-600: theme('colors.orange.600');
		--orange-700: theme('colors.orange.700');
		--orange-800: theme('colors.orange.800');
		--orange-900: theme('colors.orange.900');

		--green-25: theme('colors.green.25');
		--green-50: theme('colors.green.50');
		--green-75: theme('colors.green.75');
		--green-100: theme('colors.green.100');
		--green-200: theme('colors.green.200');
		--green-300: theme('colors.green.300');
		--green-400: theme('colors.green.400');
		--green-500: theme('colors.green.500');
		--green-600: theme('colors.green.600');
		--green-700: theme('colors.green.700');
		--green-800: theme('colors.green.800');
		--green-900: theme('colors.green.900');

		--turquoise-25: theme('colors.turquoise.25');
		--turquoise-50: theme('colors.turquoise.50');
		--turquoise-75: theme('colors.turquoise.75');
		--turquoise-100: theme('colors.turquoise.100');
		--turquoise-200: theme('colors.turquoise.200');
		--turquoise-300: theme('colors.turquoise.300');
		--turquoise-400: theme('colors.turquoise.400');
		--turquoise-500: theme('colors.turquoise.500');
		--turquoise-600: theme('colors.turquoise.600');
		--turquoise-700: theme('colors.turquoise.700');
		--turquoise-800: theme('colors.turquoise.800');
		--turquoise-900: theme('colors.turquoise.900');

		--pink-25: theme('colors.pink.25');
		--pink-50: theme('colors.pink.50');
		--pink-75: theme('colors.pink.75');
		--pink-100: theme('colors.pink.100');
		--pink-200: theme('colors.pink.200');
		--pink-300: theme('colors.pink.300');
		--pink-400: theme('colors.pink.400');
		--pink-500: theme('colors.pink.500');
		--pink-600: theme('colors.pink.600');
		--pink-700: theme('colors.pink.700');
		--pink-800: theme('colors.pink.800');
		--pink-900: theme('colors.pink.900');

		--purple-25: theme('colors.purple.25');
		--purple-50: theme('colors.purple.50');
		--purple-75: theme('colors.purple.75');
		--purple-100: theme('colors.purple.100');
		--purple-200: theme('colors.purple.200');
		--purple-300: theme('colors.purple.300');
		--purple-400: theme('colors.purple.400');
		--purple-500: theme('colors.purple.500');
		--purple-600: theme('colors.purple.600');
		--purple-700: theme('colors.purple.700');
		--purple-800: theme('colors.purple.800');
		--purple-900: theme('colors.purple.900');

		--overlay-dark: theme('colors.overlay.dark');
		--overlay-light: theme('colors.overlay.light');
		--overlay-translucent: theme('colors.overlay.translucent');

		--color-focus: theme('colors.color-focus');
		--color-error: theme('colors.color-error');
		--color-bg-error: theme('colors.color-bg-error');
		--color-bg-error-dark: theme('colors.color-bg-error-dark');
	}

	/* The following is copied from app.scss to make the components in Storybook look like they would in the AppWeb */
	html {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		box-sizing: border-box;
		font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
			'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
	}

	* {
		-webkit-tap-highlight-color: transparent;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		border-radius: 0;
		text-rendering: geometricPrecision;
		box-sizing: inherit;
	}

	*:focus {
		outline: 0;
	}

	figure {
		margin: 0;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border-width: 0;
	}
	/* END */

	/* Remove this snippet when preflight and or normalize.css is introduced into this codebase */
	*,
	::before,
	::after {
		box-sizing: border-box;
		border-width: 0;
		border-style: solid;
		border-color: currentColor;
	}

	button {
		margin: 0;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: 0;
	}
	/* END */
}

@layer utilities {
	.hyphens {
		/* Breaks words with a hyphen, but only works if html lang="xyz" is set correctly */
		word-break: break-word;
		hyphens: auto;
	}

	.placeholder-italic::placeholder {
		@apply italic;
		font-weight: 300;
	}
}
