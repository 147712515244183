@import 'theme';

.ghostButton {
	font-size: 1rem;
	text-transform: none;
	background-color: transparent;
	color: var(--gray-800);
	padding: 30px;
	border: none;
	transition-property: all;
	transition-duration: 240ms;
	transition-timing-function: ease;

	&:hover {
		color: var(--blue-500);
		background-color: transparent;
	}
}

.ghostButtonFullWidth {
	font-size: 0.75rem;
	padding: 10px 30px;
	border: none;
	width: 100%;

	&:hover {
		opacity: 0.85;
	}
}

.ghostButtonGsuite {
	@extend .ghostButtonFullWidth;
	color: var(--blue-500);
	outline: none;
}

.ghostButtonDelete {
	@extend .ghostButtonFullWidth;

	&:hover {
		color: var(--red-500);
	}
}
