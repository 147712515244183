.shadow:before {
	opacity: 0;
}

.isScrolling .shadow:before {
	opacity: 1;
}

.horribleCornerHack {
	background: linear-gradient(
		var(--gray-25) 15%,
		transparent 15%,
		transparent 85%,
		var(--gray-25) 85%
	);
}

.horribleCornerHack:after {
	background: linear-gradient(var(--white) 15%, transparent 15%, transparent 85%, var(--white) 85%);
}
