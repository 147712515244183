@import 'theme';

.userDropDown {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
	font-family: $font-px-grotesk;

	button {
		border: none;
		cursor: pointer;
		outline: inherit;
		text-align: left;
		background: none;
		padding: 0;
		display: flex;
		align-items: center;

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--color-focus);
		}

		&.userDropDownButton {
			border-radius: 50%;
		}

		@media (max-width: calc($breakpoint-width-md - 1px)) {
			&.withLogo {
				& > img {
					display: none;
				}
			}
		}

		@media (min-width: $breakpoint-width-md) {
			&.withLogo {
				display: inline-flex;
				padding: 0.25rem 0.5rem;
				align-items: center;
				gap: 0.5rem;
				border-radius: 0.25rem;
				box-shadow: inset 0 0 0 0.0625rem var(--gray-100);
				background: var(--white);

				& > img {
					max-width: 8rem;
					max-height: 1.5rem;
				}

				&:focus-visible {
					box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
					border-color: var(--color-focus);
				}

				&:hover {
					background-color: var(--gray-100);
					border-color: var(--gray-200);
				}

				&:active {
					--background-color: var(--gray-200);
					--border-color: var(--gray-300);
				}
			}
		}
	}

	.currentUser {
		display: flex;
		gap: 0.5rem;
		padding: 0.5rem 0.75rem;
		align-items: center;

		.customerName {
			color: var(--gray-800);
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 700;
			line-height: 1rem;

			max-width: 8.5rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.accountNumber {
			color: var(--gray-600);
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1rem;
		}
	}

	.logout {
		color: var(--red-500);

		button {
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			align-items: center;

			padding: 0.75rem;
			width: 100%;

			&:before {
				display: block;
				width: 1.5rem;
				aspect-ratio: 1;
				content: '';
				background: url('./img/logout.svg') no-repeat center;
			}

			&:hover {
				background: var(--red-25);
			}

			&:active {
				background: var(--red-50);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
			}
		}
	}
}
