.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	white-space: nowrap;
	user-select: none;
}

@mixin focussable() {
	transition: box-shadow 150ms;

	&:focus-visible {
		box-shadow: var(--color-focus) 0px 0px 0px 3px inset;
	}
}
