.border {
	box-shadow: inset 0 -0.0625rem var(--gray-100);
}

.borderHover:hover {
	box-shadow: inset 0 -0.0625rem var(--gray-100);
}

.borderSelected {
	box-shadow: inset 0 -0.125rem var(--blue-500);
}

.borderDisabledSelected {
	box-shadow: inset 0 -0.125rem var(--gray-400);
}
