@import 'theme';

.menuButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0.4375rem 0 0.4375rem 0;
	outline: none;
	background: none;
	border: none;
	width: 1.5rem;

	@media (min-width: $breakpoint-width-xl) {
		display: none;
	}

	div {
		background: var(--white);
		border-radius: 0.625rem;
		width: 1.5rem;
		height: 0.125rem;
		margin: 0.125rem 0;
		transition: all ease 250ms;
	}

	&:focus-visible {
		box-shadow: 0 0 0 0.1875rem var(--color-focus) inset;
	}

	&:hover {
		div {
			&:nth-child(1) {
				width: 1.375rem;
			}

			&:nth-child(2) {
				width: 1rem;
			}

			&:nth-child(3) {
				width: 1.25rem;
			}
		}
	}
}
