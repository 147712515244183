@import 'theme';

$duration: 400ms;
$easing: cubic-bezier(0.165, 0.84, 0.44, 1);

.rootContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.childContainer {
	display: flex;
	flex-direction: column;

	&:first-child {
		flex-grow: 1;
	}

	color: var(--gray-800);
	background-color: var(--gray-25);
	/* stylelint-disable-next-line @web-apps/no-custom-colors -- We do not have pandaUI box-shadow tokens yet. */
	box-shadow: -0.625rem 0 0.5rem -0.5rem rgba(0, 0, 0, 0.15);
	overflow: auto;
	flex: 1 1 100%;
	line-height: 1;

	> * {
		flex-grow: 1;
		max-width: 100%;
	}
}

.container {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-grow: 1;

	.stickyContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: 1 1 auto;
	}
}

.igelPadding {
	height: 100%;
}

.skipToContentLink:focus-visible,
.skipToContentLink:focus {
	@include focussable;

	position: static;

	clip: initial;
	height: auto;
	width: auto;
	background-color: var(--gray-75);
	position: fixed;
	z-index: 9999;
	padding: 0.75rem;
	margin: 0.75rem;
	border-radius: 0.25rem;
}

.content {
	height: 100%;
	padding: 0 1rem;

	@media (min-width: $breakpoint-width-md) {
		padding: 0 2.5rem;
	}

	@media (min-width: $breakpoint-width-lg) {
		padding: 0 2.5rem;
	}

	@media (min-width: $breakpoint-width-xl) {
		padding: 0 5rem;
	}
}
