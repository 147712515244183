@import '~normalize.css';

@import 'fonts.scss';
@import 'keyframes.scss';

@import 'components/a.scss';
@import 'components/headlines.scss';
@import 'components/intercom.scss';
@import 'components/zopim.scss';
@import 'components/input.scss';
@import 'satellite.scss';

html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	box-sizing: border-box;
	font-family: $font-px-grotesk;
	font-size: 100%;
}

* {
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-radius: 0;
	text-rendering: geometricPrecision;
	box-sizing: inherit;

	&:focus {
		outline: 0;
	}
}

figure {
	margin: 0;
}

fieldset {
	margin: 0;
	padding: 0;
	border-width: 0;
}

/*
 * panda uses newer normalize which sets border-collapse:separate where ours sets it to collapse.
 * To avoid weird suprises in the future we adjust our global styles accordingly.
 * Can probably be removed if we ever update normalize.css
 */
table {
	border-collapse: separate;
}
