@import 'theme';

@keyframes runningBar {
	0% {
		background-position: -100vw 0;
	}

	100% {
		background-position: 100vw 0;
	}
}

.loadingBar {
	display: flex;
	height: 3px;
	background: linear-gradient(
			to right,
			transparent 0%,
			var(--blue-500) 30%,
			var(--blue-500) 70%,
			transparent 100%
		)
		no-repeat;
	animation: runningBar 1.3s infinite ease-in-out;
	position: absolute;
	z-index: $layer-loadingbar;
	top: 0;
	left: 0;
	width: 100%;
}
