@import 'theme';

.bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: initial;
	flex-shrink: 200000000;
	background: var(--gray-75);
	overflow: hidden;
	background: none;
	color: var(--gray-600);
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1rem;
	padding-left: 0;

	ol {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		li:not(:first-child):not(:nth-child(2)) {
			a span {
				max-width: max-content;
			}
		}

		@media (max-width: calc(#{$breakpoint-width-xl} - 1px)) {
			margin-left: 1.5rem;
		}

		@media (max-width: calc(#{$breakpoint-width-md} - 1px)) {
			margin-left: 0;
		}
	}
}
