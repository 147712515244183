.icon-check-16 {
	width: 1rem;
	height: 1rem;
	mask-image: url(./svgs/icon-check-black-line-default-16.svg);
}

.icon-dash-16 {
	width: 1rem;
	height: 1rem;
	mask-image: url(./svgs/icon-dash-black-line-default-16.svg);
}

.icon-exclamation_mark_circle-16 {
	width: 1rem;
	height: 1rem;
	/* This icon uses a SVG-mask. If used below 20px, Safari will render it blurry. Therefore we use the @2x version.*/
	mask-image: url(./svgs/icon-exclamation_mark_circle-red-solid-default-32.svg);
	color: var(--color-error);
}

.icon-sort_arrow-16 {
	width: 0.5rem;
	height: 1rem;
	mask-image: url(./svgs/icon-sort_arrow-black-line-default-16.svg);
}

.icon-sortable_arrows-16 {
	width: 1rem;
	height: 1rem;
	mask-image: url(./svgs/icon-sortable_arrows-black-line-default-16.svg);
}

.icon-triangle_down-16 {
	width: 1rem;
	height: 1rem;
	mask-image: url(./svgs/icon-triangle_down-black-line-default-16.svg);
}
